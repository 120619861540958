import { useState } from 'react'
import './card.css'
import { ButtonLinkCard } from '../button/button'
import sold from '../../icons/sold.png'

export const CardCourse = ({ course }) => {
	const { img, title, subtitle, description, items, price, link } = course
	const [isExpanded, setIsExpanded] = useState(false)

	const toggleDetails = () => {
		setIsExpanded(prev => !prev)
	}

	return (
		<div className='cardCourse'>
			<img src={img} alt={title} />
			<h3 className='titleCardCourse'>{title}</h3>
			<p className='subTitleCardCourse'>{subtitle[0] || 'Отсутствует'}</p>
			{price === undefined && (
				<img className='soldCad' src={sold} alt='sold' />
			)}

			<div
				className={`containerHiddenCardCourse ${isExpanded ? 'expanded' : ''}`}
			>
				{description.length > 0 ? (
					description.map((item, index) => (
						<h3 className='containerDescription' key={index}>
							{item}
						</h3>
					))
				) : (
					<p>Нет описания</p>
				)}
				{subtitle[1] && <p className='titleCardCourse'>{subtitle[1]}</p>}
				<ul className='list'>
					{items.length > 0 ? (
						items.map((item, index) => (
							<li className='item' key={index}>
								{item}
							</li>
						))
					) : (
						<li>Нет элементов</li>
					)}
				</ul>

				{price !== undefined ? (
					<h2 className='price'>{price} ₽</h2>
				) : (
					<img className='soldCar' src={sold} alt='sold' />
				)}

				{price !== undefined && (
					<div className='center'>
						<ButtonLinkCard to={link} text='Купить' />
					</div>
				)}
			</div>

			{price !== undefined && !isExpanded && (
				<button className='toggleBtn' onClick={toggleDetails}>
					Подробнее
				</button>
			)}
		</div>
	)
}
