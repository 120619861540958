import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import './mySwiper.css'

import item1 from './pdf_img/item1.png'
import item2 from './pdf_img/item2.png'
import item3 from './pdf_img/item3.png'
import item4 from './pdf_img/item4.png'
import item5 from './pdf_img/item5.png'
import item6 from './pdf_img/item6.png'
import item7 from './pdf_img/item7.png'
import item8 from './pdf_img/item8.png'

import href1 from './pdf/Appearance.pdf'
import href2 from './pdf/CityLife.pdf'
import href3 from './pdf/Burnout.pdf'
import href4 from './pdf/dependentPrepositions.pdf'
import href5 from './pdf/Memory.pdf'
import href6 from './pdf/Relationship.pdf'
import href7 from './pdf/Time.pdf'
import href8 from './pdf/Emotions.pdf'
import { DownloadButton } from '../button/button'

// Данные для слайдов
const slidesData = [
	{ img: item1, href: href1, level: 'B1' },
	{ img: item2, href: href2, level: 'B1' },
	{ img: item3, href: href3, level: 'B2' },
	{ img: item4, href: href4, level: 'A2' },
	{ img: item5, href: href5, level: 'B2' },
	{ img: item6, href: href6, level: 'B1' },
	{ img: item7, href: href7, level: 'C1' },
	{ img: item8, href: href8, level: 'B2' },
]

const MySwiper = () => {
	const [slidesPerView, setSlidesPerView] = useState(4)

	const updateSlidesPerView = () => {
		const width = window.innerWidth
		if (width < 500) {
			setSlidesPerView(1) // Для мобильных устройств
		} else if (width < 768) {
			setSlidesPerView(2) // Для планшетов
		} else {
			setSlidesPerView(3) // Для десктопов
		}
	}

	useEffect(() => {
		updateSlidesPerView() // Установить изначальное количество слайдов при загрузке

		window.addEventListener('resize', updateSlidesPerView) // Отслеживание изменения размера окна
		return () => {
			window.removeEventListener('resize', updateSlidesPerView) // Очистка события при размонтировании
		}
	}, [])

	return (
		<>
			<Swiper
				className='swiper-container'
				modules={[Navigation, Pagination, Scrollbar, A11y]}
				spaceBetween={10}
				slidesPerView={slidesPerView}
				centeredSlides={false} // Изменено на false для отображения с началом страницы
				navigation
				pagination={{ clickable: true }}
				scrollbar={{ draggable: true }}
			>
				{slidesData.map((slide, index) => (
					<SwiperSlide key={index}>
						<h3 className='item_lessons'>{slide.level}</h3>
						<img className='img_items_pdf' src={slide.img} alt='img' />
						<DownloadButton href={slide.href} text='скачать' />
					</SwiperSlide>
				))}
			</Swiper>
			<div className='swiper-pagination' style={{ marginTop: '20px' }} />
		</>
	)
}

export default MySwiper
