import './button.css'
import { Link } from 'react-router-dom'

export const ButtonLink = ({text, to}) => {
	return (
		<Link to={to} className='linkClear'>
			<button className='buttonLink'>
        {text}
			</button>
		</Link>
	)
}

export const ButtonLinkCard = ({ text, to }) => {
	return (
		<Link to={to} className='linkClear'>
			<button className='buttonLinkCard'>{text}</button>
		</Link>
	)
}

export const DownloadButton = ({ href, text }) => {
	const handleDownload = () => {
		// Создаем временный элемент <a>, чтобы инициировать загрузку
		const link = document.createElement('a')
		link.href = href
		link.download = href.split('/').pop() // Получаем имя файла из URL
		document.body.appendChild(link) // Добавляем элемент на страницу
		link.click() // Имитируем клик на элементе
		document.body.removeChild(link) // Удаляем элемент после клика
	}

	return (
		<button className='buttonLink' onClick={handleDownload}>
			{text}
		</button>
	)
}


export const ButtonLinkClear = ({ text, to }) => {
	return (
		<Link to={to} className='linkClear'>
			<button className='buttonLinkClear'>{text}</button>
		</Link>
	)
}

export const LogoLink = () => {
	return (
		<Link to="/" className='linkClear'>
			<div className='logo'>
				<div className='logoCircle'></div>
				<div className='borderLogo'></div>
				<div className='containerTextLogo'>
					<h2 className='textLogo'>Teachers</h2>
					<h2 className='textLogo'>Anonymous</h2>
				</div>
			</div>
		</Link>
	)
}

export const SocialLink = ({ url, imageSrc, altText }) => {
	return (
		<Link to={url}
			className='socialLink'
			target='_blank'
			rel='noopener noreferrer'
		>
			<img className='socialLinkImg' src={imageSrc} alt={altText} />
		</Link>
	)
}

 

