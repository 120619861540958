import './mainPage.css'
import Section from "../../components/section/section";
import { ButtonLink, ButtonLinkClear } from '../../components/button/button';
import iconWoman from '../../icons/woman.svg'
import { CardCourse } from '../../components/card/card';
import { data } from '../../dataCourse.js'
import MySwiper from '../../components/swiper/swiper.jsx'
import Footer from '../../components/footer/Footer.jsx';

const MainPage = () => {
  return (
		<>
			<Section>
				<div className='containerInSection'>
					<h1 className='titleSection'>Обучающее пространство для учителей</h1>
					<div className='containerInContainer'>
						<div className='containerTextAndButton'>
							<h2 className='subTitleSection'>
								Сделайте свои уроки <br />
								продуктивнее
							</h2>
							<div className='containerButtonGroup'>
								<ButtonLink
									to='https://t.me/teachers_payment_bot'
									text='Купить сборник Warm Up activities'
								/>
								<ButtonLinkClear
									to='https://t.me/teachers_payment_bot'
									text='Узнать подробнее'
								/>
							</div>
						</div>
						<div className='containerIcon'>
							<img
								className='iconImagTop'
								src={iconWoman}
								alt='icon'
								loading='lazy'
							/>
						</div>
					</div>
				</div>
			</Section>
			<Section>
				<h2 className='titleSectionBlue'>Курсы для преподавателей</h2>
				<div className='containerCard'>
					{data.map((course, idx) => (
						<CardCourse course={course} key={idx} />
					))}
				</div>
			</Section>
			<Section>
				<h2 className='titleSectionBlue'>
					Скачайте PDF материалы к вашим урокам
				</h2>
				<MySwiper />
			</Section>
			<Footer />
		</>
	) 
}
 
export default MainPage;