import ScrollToTop from './utils/scrollToTop'
import { Route, Routes } from 'react-router-dom'
import React from 'react'
import Layout from './components/layout/layout'
import MainPage from './pages/mainPage/MainPage'
import Offer from './pages/offer/Offer'


function App() {
	return (
		<Layout>
			<ScrollToTop />
			<Routes>
				<Route path='/' element={<MainPage />} />
				<Route path='/offer' element={<Offer />} />
			</Routes>
		</Layout>
	)
}

export default App


