import isi from './img/isi.svg'
import telega from './img/telegram.png'
import vk from './img/vk.png'
import './footer.css'
import { NavLink } from 'react-router-dom'
import { SocialLink } from '../button/button'

const Footer = () => {
	const socialLinks = [
		{ url: 'https://vk.com/club220249180', imageSrc: vk, altText: 'vk' },
		{
			url: 'https://t.me/teachers_anonymous',
			imageSrc: telega,
			altText: 'telegram',
		},
	]

	return (
		<footer className='footer'>
			<div className='footerStart'>
				<img className='isiLogo' src={isi} alt='Isin' />
				<div className='footerOffer'>
					<NavLink to='/offer'>
						<p className='publicBtn textFooter'>публичная оферта</p>
					</NavLink>
					<p className='textFooter'>ИНН: 540862021898</p>
					<h4 className='textFooter'>Нескороженая Яна Александровна</h4>
				</div>
			</div>

			<div className='footerEnd'>
				{socialLinks.map(({ url, imageSrc, altText }, index) => (
					<SocialLink
						key={index}
						url={url}
						imageSrc={imageSrc}
						altText={altText}
					/>
				))}
			</div>
		</footer>
	)
}

export default Footer
