import './header.css'
import { ButtonLink, LogoLink } from '../button/button'

const Header = () => {
	return (
		<div className='headerContainer'>
			<div className='block leftB'></div>
			<header className='header'>
				<LogoLink />
				<ButtonLink
					to='https://t.me/teachers_anonymous'
					text='Telegram channel'
				/>
			</header>
			<div className='block rightB'></div>
		</div>
	)
}

export default Header
