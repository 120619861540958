import { ButtonLink } from '../../components/button/button'
import './offer.css'
import React from 'react'

const Offer = () => {
	return (
		<div className='popUp_public'>
			<div className='pubic'>
				<h2 className='public_title'>ОФЕРТА (ПРЕДЛОЖЕНИЕ)</h2>
				<h4 className='public_title_mini'>о заключении Договора</h4>

				<div className='wrp_pubic'>
					<p className='text'>г.Санкт-Петербург</p>
					<p className='text'>действующая редакция от 03.11.2023 г.</p>
				</div>

				<p className='text'>
					Настоящая Оферта является предложением Нескороженой Яны Александровны,
					далее именуемой как «Исполнитель», любому заинтересованному
					физическому лицу (далее - «Заказчик») заключить договор об оказании
					услуг по предоставлению доступа к материалам, размещенным на сайте в
					сети Интернет по адресу https://Teachers-Anonymous.ru (далее -
					«Информационный ресурс»), на изложенных ниже условиях. Исполнитель
					оставляет за собой право внести изменения в условия Оферты и/или
					отозвать Оферту в любой момент по своему усмотрению. Заказчик
					производит полный и безоговорочный акцепт (принятие) оферты
					Исполнителя в отношении выбранных материалов путем предварительной
					оплаты в полном объеме (100%) их стоимости в порядке, установленном в
					разделе 4 Договора, что влечет заключение Договора между Заказчиком и
					Исполнителем в соответствии с п. 3 ст. 438 Гражданского кодекса РФ.
					Оплачивая стоимость предоставления доступа к любому из материалов,
					размещенных на Информационном ресурсе, Заказчик подтверждает
					ознакомление и безусловное принятие (акцепт) Оферты. С момента
					зачисления предварительной оплаты в полном объеме (100%) на счет
					Исполнителя, Договор считается заключенным в редакции, актуальной на
					момент совершения оплаты.
				</p>

				<p className='title_point_public'>1. ПРЕДМЕТ ДОГОВОРА</p>
				<p className='text_point_public'>
					1.1. Исполнитель обязуется за плату оказать Заказчику услуги по
					предоставлению доступа к материалам, размещенным на Информационном
					ресурсе (далее – «Материалы»), а Заказчик обязуется оплатить услуги
					Исполнителя на условиях Договора.{' '}
				</p>
				<p className='text_point_public'>
					1.2. Услуги оказываются посредством предоставления доступа к
					материалам, выбранным и оплаченным Заказчиком. В случае, если
					предоставление доступа к материалам осуществляется в соответствии с
					условиями одного из тарифов, указанных на Информационном ресурсе –
					оказание услуг осуществляется в соответствии с условиями тарифа,
					выбранного Заказчиком.
				</p>
				<p className='text_point_public'>1.3. Срок оказания услуг:</p>
				<p className='text_point_public'>
					1.3.1. предоставление доступа к материалам онлайн-курсов, размещенным
					в разделе «Курсы для учителей» Информационного ресурса - 3 (три)
					месяца, исчисляемые с момента предоставления Заказчику доступа к
					материалам, предусмотренным для освоения выбранного Заказчиком
					онлайн-курса;
				</p>
				<p className='text_point_public'>
					1.3.2. предоставление доступа к материалам «35 ESL Warm-up
					Activities», размещенным на Информационном ресурсе – бессрочно
				</p>
				<p className='text_point_public'>
					1.4. Необходимым условием оказания услуг является наличие у Заказчика
					качественного доступа и подключения к
					информационно-телекоммуникационной сети Интернет.
				</p>
				<p className='text_point_public'>
					1.5. Предоставление Заказчику доступа к материалам осуществляется
					через электронную почту в течение 24 часов с момента поступления
					стоимости услуг на счет Исполнителя.
				</p>
				<p className='text_point_public'>
					1.6. Материалы являются объектами интеллектуальной собственности в
					соответствии с частью IV Гражданского кодекса РФ, исключительные права
					на которые принадлежат Исполнителю. Исполнитель предоставляет
					Заказчику право использования материалов на условиях простой
					(неисключительной) безвозмездной лицензии в соответствии с Договором.
				</p>
				<p className='text_point_public'>
					1.7. Территория предоставления права использования материалов: весь
					мир.{' '}
				</p>
				<p className='text_point_public'>
					1.8. Право использования материалов предоставляется Исполнителем
					Заказчику на срок, установленный п. 1.3. Договора.
				</p>

				<p className='title_point_public'>2. ПРАВА ИСПОЛНИТЕЛЯ И ЗАКАЗЧИКА</p>
				<p className='text_point_public'>2.1. Исполнитель вправе:</p>
				<p className='text_point_public'>
					2.1.1. В течение срока размещения материалов на Информационном ресурсе
					Исполнитель вправе без согласия и уведомления Заказчика изменять
					стоимость услуг в отношении предоставления доступа к материалам, а
					также по истечении определенного периода времени предоставлять доступ
					к определенным материалам без взимания платы. При этом, стоимость
					услуг, оплаченная Заказчиком в соответствии с разделом 4 Договора,
					изменению не подлежит.
				</p>
				<p className='text_point_public'>
					2.1.2. Приостанавливать работу Информационного ресурса для проведения
					необходимых плановых профилактических и ремонтных работ на технических
					ресурсах Исполнителя, а также внеплановых работ в аварийных ситуациях.
				</p>
				<p className='text_point_public'>2.2. Заказчик вправе:</p>
				<p className='text_point_public'>
					2.2.1. Получать информацию от Исполнителя по вопросам организации и
					обеспечения надлежащего оказания услуг, предусмотренных настоящим
					Договором.
				</p>
				<p className='text_point_public'>
					2.2.2. Обращаться к Исполнителю по вопросам, касающимся процесса
					оказания услуг по настоящему Договору, а также по вопросам, касающимся
					функционирования Информационного ресурса.
				</p>

				<p className='title_point_public'>
					3. ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ И ЗАКАЗЧИКА
				</p>
				<p className='text_point_public'>3.1. Исполнитель обязан:</p>
				<p className='text_point_public'>
					3.1.1. Размещать на Информационном ресурсе актуальную информацию о
					материалах, в том числе о содержании, объеме, стоимости и
					продолжительности срока предоставления к ним доступа.
				</p>
				<p className='text_point_public'>3.2. Заказчик обязан:</p>
				<p className='text_point_public'>
					3.2.1. Самостоятельно отслеживать сроки освоения материалов, в случае,
					когда доступ к материалам предоставляется на срок, установленный п.
					1.3.1. Договора.
				</p>
				<p className='text_point_public'>
					3.2.2. Использовать материалы только в целях овладения знаниями,
					умениями, навыками и компетенциями (т.е. обучения); Заказчик не вправе
					использовать материалы (полностью или частично) в каких-либо иных
					целях, в том числе коммерческих.
				</p>
				<p className='text_point_public'>
					3.2.3. Осуществлять оплату услуг в соответствии с разделом 4 Договора.
				</p>

				<p className='title_point_public'>
					4. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ
				</p>
				<p className='text_point_public'>
					4.1. Стоимость услуг в отношении каждого из материалов указана на
					Информационном ресурсе на странице покупки соответствующего материала.
					При оплате стоимости услуг Заказчик безоговорочно соглашается со
					стоимостью таких услуг.
				</p>
				<p className='text_point_public'>
					4.2. Стоимость услуг устанавливается в рублях РФ, НДС не облагается в
					связи с применением Исполнителем Федеральный закон от 27.11.2018 №
					422-ФЗ.
					<br /> Оплата услуг осуществляется Заказчиком на условиях 100%
					предварительной оплаты одним из следующих безналичных способов оплаты:
					<br />
					банковские карты выпущенные в россии
					<br /> электронные кошельки
					<br />
					оплата через приложения банков. Оплата услуг посредством международных
					платежных систем Visa International, MasterCard Worldwide, American
					Express, JCB. DC является бесплатным способом оплаты услуг по
					Договору. При оплате услуг с использованием иных способов оплаты
					возможно взимание комиссии лицами, обеспечивающими осуществление
					платежей. При оплате услуг с использованием иных способов оплаты
					расходы на осуществление таких платежей несет Заказчик. Заказчик
					настоящим дает согласие на оплату комиссий третьим лицам,
					обеспечивающим осуществление платежей за услуги.
				</p>
				<p className='text_point_public'>
					4.3. Обязательство Заказчика по оплате услуг считается исполненным
					надлежащим образом с момента зачисления денежных средств в полном
					объеме на расчxетный счет Исполнителя.
				</p>
				<p className='text_point_public'>
					4.4. Любой платеж, совершенный от имени Заказчика и/или с
					использованием платежных реквизитов/средств Заказчика, считается
					произведенным самим Заказчиком.
				</p>

				<p className='title_point_public'>5. ПРИЕМКА ОКАЗАННЫХ УСЛУГ</p>
				<p className='text_point_public'>
					5.1. Услуги, оказанные по настоящему Договору, считаются оказанными и
					принятыми в полном объеме с момента завершения срока, установленного
					п. 1.3. настоящего Договора. Стороны не подписывают Акт об оказанных
					услугах для подтверждения факта их надлежащего оказания. Услуги
					считаются принятыми Заказчиком без замечаний, если он не сообщил о
					замечаниях к ним в течение 3 (трех) календарных дней с момента
					окончания срока оказания услуг.
				</p>

				<p className='title_point_public'>
					6. СРОК ДЕЙСТВИЯ ДОГОВОРА. ОСНОВАНИЯ ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ ДОГОВОРА
				</p>
				<p className='text_point_public'>
					6.1. Настоящий Договор считается заключенным с момента акцепта
					Заказчиком оферты Исполнителя и действует до полного исполнения
					Сторонами своих обязательств по Договору.
				</p>
				<p className='text_point_public'>
					6.2. Настоящий Договор может быть изменен или расторгнут по
					основаниям, предусмотренным законодательством Российской Федерации.
				</p>
				<p className='text_point_public'>
					6.3. Настоящий Договор может быть расторгнут по инициативе Исполнителя
					в одностороннем порядке также в случаях: <br />
					а) просрочки оплаты стоимости услуг;
					<br />
					б) невозможности надлежащего исполнения обязательства по оказанию
					услуг вследствие действий (бездействия) Заказчика, в том числе, в
					случае блокировки Заказчику доступа к Информационному ресурсу в
					соответствии с п. 7.3 Договора.
				</p>
				<p className='text_point_public'>
					6.4. Полный возврат стоимости услуг Заказчику может быть осуществлен
					Исполнителем не позднее момента получения доступа к материалам и
					только на условиях, указанных в данном пункте: <br />- Заказчик должен
					направить Исполнителю надлежаще оформленное и заполненное письменное
					Заявление о расторжении договора и возврате денежных средств, не
					позднее указанного в настоящем пункте срока по электронной почте
					Исполнителя, указанной в реквизитах настоящего Договора. При
					направлении Заказчиком Заявления на полный возврат в сроки, отличные
					от сроков, указанных в настоящем пункте, полный возврат денежных
					средств Исполнителем не осуществляется, заявление Заказчика на
					осуществление полного возврата не рассматривается.
					<br />- Полный возврат денежных средств Заказчику осуществляется за
					вычетом фактических затрат Исполнителя (комиссии банковских, кредитных
					организаций и соответствующих платежных систем за получение денежных
					средств от Заказчика и за осуществление возврата денежных средств
					Заказчику).
				</p>
				<p className='text_point_public'>
					6.5. Частичный возврат денежных средств Заказчику осуществляется за
					вычетом:
					<br />- стоимости услуг, фактически оказанных Исполнителем до момента
					получения Исполнителем Заявления на частичный возврат. К фактически
					оказанным услугам относятся уроки видеоматериалы и иные материалы, к
					которым Исполнителем был предоставлен доступ Заказчику;
					<br />- иных фактических затрат Исполнителя включая, но не
					ограничиваясь, комиссии банковских, кредитных организаций и
					соответствующих платежных систем за получение денежных средств от
					Заказчика и за осуществление возврата денежных средств Заказчику.
				</p>
				<p className='text_point_public'>
					6.6. В случае удовлетворения требования о полном или частичном
					возврате денежных средств Исполнитель осуществляет возврат в
					соответствующем размере и уведомляет о нем Заказчика в течение 10
					рабочих дней с момента получения заявления.
				</p>

				<p className='title_point_public'>7. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
				<p className='text_point_public'>
					7.1. За неисполнение или ненадлежащее исполнение своих обязательств по
					Договору Стороны несут ответственность в соответствии с настоящим
					Договором и действующим законодательством Российской Федерации.
				</p>
				<p className='text_point_public'>
					7.2. Исполнитель не несет ответственности за любые ошибки, упущения,
					прерывания, удаление, дефекты, задержку в обработке или передаче
					данных, сбое линий связи, кражу, уничтожение или неправомерный доступ
					третьих лиц к материалам и иному видеоконтенту, размещенному на
					Информационном ресурсе. Исполнитель не отвечает за любые технические
					сбои или иные проблемы любых телефонных сетей или служб, компьютерных
					систем, серверов или провайдеров, компьютерного или телефонного
					оборудования, программного обеспечения, сбоев сервисов электронной
					почты или скриптов по техническим причинам.
				</p>
				<p className='text_point_public'>
					7.3. Исполнитель вправе заблокировать Заказчику доступ к
					Информационному ресурсу и/или услугам (в том числе оплаченным) в
					случае нарушения Заказчиком условий настоящего Договора и в случае,
					если Исполнитель сочтет действия Заказчика мошенническими или
					направленными на порчу Информационного ресурса или подрыв его
					репутации, в том числе организацию DDoS-атаки, проведение фишинговой
					атаки, попытку сканирования и взлома Информационного ресурса, и т.п.
					При этом денежные средства, перечисленные Заказчиком за услуги,
					возврату не подлежат.
				</p>
				<p className='text_point_public'>
					7.4. Стороны освобождаются от ответственности за неисполнение или
					ненадлежащее исполнение обязательств по настоящему Договору,
					обусловленное обстоятельствами непреодолимой силы (форс-мажор), то
					есть чрезвычайными и непредотвратимыми обстоятельствами, возникшими
					после заключения настоящего Договора помимо воли и желания Сторон и
					которые нельзя предвидеть или избежать. Факт возникновения
					обстоятельств непреодолимой силы должен быть подтвержден компетентным
					органом по месту действия обстоятельств непреодолимой силы.
				</p>
				<p className='text_point_public'>
					7.5. В случае нарушения Заказчиком акцептованных условий публичной
					оферты и его условий, денежные средства Исполнителем не возвращаются и
					идут в счет погашения убытков Исполнителя, а также расцениваются в
					качестве неустойки за нарушение условий Договора Заказчиком в размере
					100% от стоимости оплаченных Заказчиком услуг.
				</p>

				<p className='title_point_public'>8. ИНЫЕ УСЛОВИЯ</p>
				<p className='text_point_public'>
					8.1. Информационное взаимодействие Заказчика и Исполнителя в рамках
					Договора осуществляется по электронной почте. Адрес электронной почты
					Исполнителя указан в Договоре.
				</p>
				<p className='text_point_public'>
					8.2. Документы, имеющие отношение к Договору, могут быть переданы
					Сторонами друг другу в порядке, предусмотренном п. 8.1 Договора.
					Переписка Сторон по электронной почте имеет юридическую силу, в том
					числе в случае судебного разбирательства. Такой способ обмена
					документами и информацией является надлежащим.
				</p>
				<p className='text_point_public'>
					8.3. Соглашаясь с условиями настоящего Договора, Заказчик заверяет и
					гарантирует: достоверность сведений, указываемых им при заключении
					настоящего Договора; добровольность заключения настоящего Договора, в
					т.ч. ознакомление со всеми условиями предоставления услуг
					Исполнителем, их понимание и полное и безоговорочное согласие с ними;
					даёт согласие на обработку своих персональных данных в соответствии с
					Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных»
					в целях исполнения настоящего Договора как с использованием
					автоматизированных средств обработки персональных данных, так и без
					использования средств автоматизации; наличие технической возможности
					для получения доступа к материалам на условиях, предусмотренных
					Договором.
				</p>
				<p className='text_point_public'>
					8.4. Заказчик, являющийся несовершеннолетним в возрасте от
					четырнадцати до восемнадцати лет, гарантирует, что им получено
					письменное согласие своего законного представителя (одного из
					родителей, усыновителей, попечителя) на заключение Договора в
					соответствии с требованиями ст. 26 Гражданского кодекса РФ.
				</p>
				<p className='text_point_public'>
					8.5. Все споры, разногласия и претензии, которые могут возникнуть по
					Договору, подлежат урегулированию в претензионном порядке. Сторона,
					считающая свои права нарушенными, направляет другой Стороне по адресу
					электронной почты претензию с указанием возникших разногласий. Если
					спор не будет урегулирован в течение 10 рабочих дней с момента
					получения Стороной претензии, спор передается на рассмотрение в
					соответствующий суд Российской Федерации по месту нахождения
					Исполнителя, если иное императивно не предусмотрено действующим
					применимым законодательством.
				</p>
				<p className='text_point_public'>
					8.6. Во всем остальном, что не предусмотрено настоящим Договором,
					Стороны руководствуются действующим законодательством Российской
					Федерации.
				</p>

				<p className='title_point_public'>РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ:</p>
				<p className='text_point_public'>Нескороженая Яна Александровна</p>
				<p className='text_point_public'>
					Адрес электронной почты: teachers.anonymous@yandex.ru
				</p>
				<p className='text_point_public'>тел. +7(952)-217-12-48</p>
				<p className='text_point_public'>ИНН: 540862021898</p>
				<p className='text_point_public'>
					Банковские реквизиты: Р/С 408117810844053290580 Сибирский банк ПАО
					Сбербанк
				</p>
				<p className='text_point_public'>К/С 30101810500000000641</p>
				<p className='text_point_public'>БИК 045004641</p>
			</div>
			<div className='containerBtnRight'>
				<ButtonLink to='/' text='на главную' />
			</div>
		</div>
	)
}

export default Offer
